import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @description cria novo usuário
 * @param {object} form - Dados do usuário.
 * @param  {string} pkBigcompany - ID da empresa.
 *
 */
export function createUser({ form, pkBigcompany }) {
  const body = {
    level: form?.parent?.pk,
    user: {
      ...form,
      first_name: form?.first_name?.trim(),
      last_name: form?.last_name?.trim(),
      username: form?.email?.trim(),
    },
    units: form?.units?.map((unit) => unit?.id),
  };

  return mainService.post(
    `v2/bigcompany/${pkBigcompany}/user_access_levels`,
    body,
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
}

/**
 * --------------------------------------------------
 * @description atualiza usuário
 *  @param  {object} form  - Dados do usuário.
 *  @param  {string} pkBigcompany  - ID da empresa.
 *  @param  {string} userId  - ID do usuário.
 */
export function updateUser({ form, pkBigcompany, userId }) {
  const body = {
    level: form?.parent?.pk,
    user: {
      ...form,
      first_name: form?.first_name?.trim(),
      last_name: form?.last_name?.trim(),
      username: form?.email?.trim(),
    },
  };
  return mainService.patch(
    `v2/bigcompany/${pkBigcompany}/user_access_levels/${userId}`,
    body,
    {
      headers: {
        "notifications-front-banned": true,
      },
    }
  );
}

/**
 * --------------------------------------------------
 * @description remove usuário
 * @param {string} pkBigcompany - ID da empresa.
 * @param {string} userId - ID do usuário.
 */
export function removeUser({ pkBigcompany, userId }) {
  return mainService.delete(
    `v2/bigcompany/${pkBigcompany}/user_access_levels/detach`,
    {
      data: {
        users: [userId],
      },
    }
  );
}
