import { all, takeLatest } from "redux-saga/effects";

import { Types as CategoriesTypes } from "../ducks/categories";
import { Types as JobSettingsTypes } from "../ducks/jobSettings";
import { Types as LanguagesTypes } from "../ducks/languages";
import { Types as LoginTypes } from "../ducks/auth";
import { Types as StatusTypes } from "../ducks/status";
import { Types as WalletTypes } from "../ducks/wallet";
import { Types as UserTypes } from "../ducks/user";
import { Types as SearchNPSTypes } from "../ducks/searchNPS";
import { Types as SupervisorsTypes } from "../ducks/supervisors";
import { Types as HelpLinkTypes } from "../ducks/helpLink";
import { Types as DetailsVacancyTypes } from "../ducks/vacancy";
import { Types as WalletBigCompanyTypes } from "../ducks/walletBigCompany";
import { Types as TrainingTypes } from "store/ducks/training";
import { Types as NotificationSettingsTypes } from "store/ducks/notificationSettings";
import { Types as CommercialSettingsTypes } from "store/ducks/commercialSettings";
import { Types as NotificationPreferencesTypes } from "store/ducks/notificationPreferences";
import { Types as BigcompanyProfileTypes } from "store/ducks/bigcompany/profile";
import { Types as BigcompanySettingsTypes } from "store/ducks/bigcompany/settings";
import { Types as BigcompanySecurityTypes } from "store/ducks/bigcompany/security";
import { Types as EstablishmentSettingsTypes } from "store/ducks/establishment/settings";
import { Types as ExtraDataTypes } from "store/ducks/extraData";
import { Types as EstablishmentAddressTypes } from "store/ducks/establishment/address";
import { Types as EstablishmentTaxAddressActions } from "store/ducks/establishment/taxAddress";
import { Types as ApprovalsTypes } from "store/ducks/bigcompany/approvals";
import { Types as SubscriptionTypes } from "store/ducks/subscription";
import { Types as LevelsUsersTypes } from "store/ducks/levelsUsers";
import { Types as TwoFactorAuthenticationTypes } from "store/ducks/2fa";
import { Types as ContractTypes } from "store/ducks/contract";
import { Types as SubscriptionSummaryTypes } from "store/ducks/subscriptionSummary";

import { login } from "./auth";
import { getCategories } from "./categories";
import { getJobSettings } from "./jobSettings";
import { getLanguages } from "./languages";
import { getStatus } from "./status";
import { getUser } from "./user";
import { getSupervisors } from "./supervisors";
import { getWallet } from "./wallet";
import { getSearchNPS } from "./searchNPS";
import { getHelpLink } from "./helpLink";
import { getSelectedWorker } from "./vacancy";
import { getWalletBigCompany } from "./walletBigCompany";
import { getVideoRoles } from "./training";
import { getNotificationSettings } from "./notificationSettings";
import { getCommercialSettings } from "./commercialSettings";
import { getPreferencesNotification } from "./notificationPreferences";
import { getBigcompanyProfile } from "./bigcompany/profile";
import { getBigcompanySettings } from "./bigcompany/settings";
import { getBigcompanySecurity } from "./bigcompany/security";
import { getEstablishmentSettings } from "./establishment/settings";
import { getEstablishmentAddress } from "./establishment/address";
import { getExtraData } from "./extraData";
import { getEstablishmentTaxAddress } from "./establishment/taxAddress";
import { getApprovalsPending } from "./bigcompany/approvals";
import { getSubscription } from "./subscription";
import { getLevelsUsers } from "./levelsUsers";
import { getTwoFactorAuthentication } from "./2fa";
import { getContract } from "./contract";
import { getSubscriptionSummary } from "./subscriptionSummary";

export default function* rootSaga() {
  yield all([
    // JOB SETTINGS
    takeLatest(CategoriesTypes.GET_CATEGORIES_REQUEST, getCategories),
    // JOB SETTINGS
    takeLatest(JobSettingsTypes.JOB_SETTINGS_REQUEST, getJobSettings),
    // LANGUAGES
    takeLatest(LanguagesTypes.LANGUAGES_REQUEST, getLanguages),
    // LOGIN
    takeLatest(LoginTypes.LOGIN_REQUEST, login),
    // STATUS
    takeLatest(StatusTypes.STATUS_REQUEST, getStatus),
    // Wallet
    takeLatest(WalletTypes.WALLET_REQUEST, getWallet),
    // USER
    takeLatest(UserTypes.GET_USER_REQUEST, getUser),
    // SUPERVISORS
    takeLatest(SupervisorsTypes.GET_SUPERVISORS_REQUEST, getSupervisors),
    // SEARCH NPS
    takeLatest(SearchNPSTypes.REQUEST, getSearchNPS),
    // HELP LINK WHATSAPP
    takeLatest(HelpLinkTypes.HELP_LINK_REQUEST, getHelpLink),
    // SELECT WORKER
    takeLatest(DetailsVacancyTypes.SELECTED_WORKER_REQUEST, getSelectedWorker),
    // Wallet Big Company
    takeLatest(
      WalletBigCompanyTypes.WALLET_REQUEST_BIG_COMPANY,
      getWalletBigCompany
    ),
    // TRAINING
    takeLatest(TrainingTypes.REQUEST_VIDEO_ROLES, getVideoRoles),
    // NOTIFICATION SETTINGS
    takeLatest(NotificationSettingsTypes.REQUEST, getNotificationSettings),
    // COMMERCIAL SETTINGS
    takeLatest(CommercialSettingsTypes.REQUEST, getCommercialSettings),
    // PREFERENCES NOTIFICATIONS
    takeLatest(
      NotificationPreferencesTypes.NOTIFICATION_PREFERENCES_REQUEST,
      getPreferencesNotification
    ),
    // BIGCOMPANY PROFILE
    takeLatest(BigcompanyProfileTypes.REQUEST, getBigcompanyProfile),
    // BIGCOMPANY SETTINGS
    takeLatest(BigcompanySettingsTypes.REQUEST, getBigcompanySettings),
    // BIGCOMPANY SECURITY
    takeLatest(BigcompanySecurityTypes.REQUEST, getBigcompanySecurity),
    // ESTABLISHMENT SETTINGS
    takeLatest(EstablishmentSettingsTypes.REQUEST, getEstablishmentSettings),
    // EXTRA DATA
    takeLatest(ExtraDataTypes.REQUEST, getExtraData),
    // ESTABLISHMENT ADDRESS
    takeLatest(EstablishmentAddressTypes.REQUEST, getEstablishmentAddress),
    // ESTABLISHMENT TAX ADDRESS
    takeLatest(
      EstablishmentTaxAddressActions.REQUEST,
      getEstablishmentTaxAddress
    ),
    // APPROVALS PENDING
    takeLatest(ApprovalsTypes.REQUEST, getApprovalsPending),
    // v2 SUBSCRIPTION
    takeLatest(SubscriptionTypes.REQUEST, getSubscription),
    // v2 SUBSCRIPTION SUMMARY
    takeLatest(SubscriptionSummaryTypes.REQUEST, getSubscriptionSummary),
    // LEVELS USERS
    takeLatest(LevelsUsersTypes.LEVELS_REQUEST, getLevelsUsers),
    // 2FA
    takeLatest(
      TwoFactorAuthenticationTypes.REQUEST,
      getTwoFactorAuthentication
    ),
    // CONTRACT
    takeLatest(ContractTypes.REQUEST, getContract),
  ]);
}
