import OneSignal from "react-onesignal";
import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as StatusActions } from "store/ducks/status";
import { Creators as WalletActions } from "store/ducks/wallet";
import { Creators as CommercialSettingsActions } from "store/ducks/commercialSettings";
import { Creators as AddressActions } from "store/ducks/establishment/address";
import { Creators as TaxAddressActions } from "store/ducks/establishment/taxAddress";
import { Creators as TwoFactorAuthenticationActions } from "store/ducks/2fa";
import { Creators as SubscriptionSummaryAction } from "store/ducks/subscriptionSummary";
import { Creators as CurrentUserActions } from "store/ducks/currentUser";
import { Creators as EstablishmentSettingsActions } from "store/ducks/establishment/settings";
import { Cookie } from "utils/cookies";

export function* getStatus(action) {
  try {
    const establishment_id = Cookie.get("unit-pk")
    let urlData = "v2/establishment/user_to_establishment/data"

    if (establishment_id) urlData += `?establishment_id=${establishment_id}`

    const response = yield call(MainService.get, urlData);

    const estabPk = response?.data?.establishment_id;

    let { data } = yield call(
      MainService.get,
      `v2/establishment/${estabPk}/data`
    );
    data = {
      is_manager: response?.data?.is_manager,
      user: response?.data?.user,
      ...data,
    };

    if (data?.id) {
      const isPmeOrUnitWithSeparatedBilling = !(
        !!data?.bigcompany?.id && !data?.bigcompany?.separated_billing
      );

      yield put(
        CommercialSettingsActions.request({
          pk: data?.id,
          user: "establishment",
          required: isPmeOrUnitWithSeparatedBilling,
        })
      );

      if (isPmeOrUnitWithSeparatedBilling) {
        yield put(WalletActions.setWallet(data?.id));
      }

      yield put(
        TaxAddressActions.request({
          pk: data?.id,
        })
      );
      yield put(
        AddressActions.request({
          pk: data?.id,
        })
      );
    }
    yield put(StatusActions.statusSuccess(data));

    const isPME = !data?.bigcompany?.id;
    yield put(
      TwoFactorAuthenticationActions.request({
        pk: isPME ? data?.id : data?.bigcompany?.id,
        user: isPME ? "establishment" : "bigcompany",
      })
    );

    yield put(
      SubscriptionSummaryAction.request({
        user: "establishment",
        userId: data?.id,
      })
    );

    yield put(EstablishmentSettingsActions.request({ pk: data?.id }));

    yield put(
      CurrentUserActions.setUser({
        pk: data?.id,
        user: "establishment",
      })
    );
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(StatusActions.statusFailure(error.message));
    }
  }
}
