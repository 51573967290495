import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @description Retorna a listagem dos modais automaticos
 * getAutomaticModal()
 */
export const getAutomaticModal = () => {
  return mainService.get("v2/user/modals_auto");
};

/**
 * @description Atualiza quando um modal automatico é visto
 * @param {object} body - objeto da requisição
 * @param {string} body.modal - modal a ser atualizado
 * @param {string} body.new_date_view - próxima data que o modal deve aparecer
 * @example setModal({
 *  modal: "nps",
 *  new_date_view: "2024-12-16",
 * })
 */
export const setModal = ({ body }) => {
  return mainService.post("v2/user/modals_auto", body);
};
