import { store } from "store";
import { Creators as UserActions } from "store/ducks/user";
import { Creators as StatusActions } from "store/ducks/status";
import { Creators as PersistFilters } from "store/ducks/persistedFilters";
import { format } from "date-fns";
import i18n from "i18n";
import { toPattern } from "vanilla-masker";
import { Cookie } from "utils/cookies";
import OneSignal from "react-onesignal";

import { Toast } from "libs/purple-ui";

import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";

import { MONTHS_REDUCE, MONTHS_REDUCE_SIGLA } from "assets/constants";
import mainService from "services/mainService";
import moment from "moment";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

import DefaultUserPicture from "assets/imgs/user.jpg";
import personAvailable20Regular from "@iconify-icons/fluent/person-available-20-regular";
import money20Regular from "@iconify-icons/fluent/money-20-regular";
import arrowHookUpLeft20Regular from "@iconify-icons/fluent/arrow-hook-up-left-20-regular";
import depositFeeIcon from "assets/imgs/deposit_fee_icon.svg";
import { getCapitalizeName } from "modules/manager/pages/professionals/utils";

/**
 * if response.status matches with 400, 403, 404, 500, 502 or if
 * navigator is offline, it returns TRUE, else it returns
 * FALSE.
 * @param {Object<Response>} res Response object
 * @returns {boolean}
 */
export function resolveErrorNotification({
  response,
  fireToast = true,
  isCancelled = false,
}) {
  // ONLINE
  if (!navigator.onLine) {
    if (!!fireToast) {
      Toast.warning(i18n.t("validation:api.errors.offline"));
    }
    return {
      checked: true,
      status: "offline",
      message: i18n.t("validation:api.errors.offline"),
    };
  }
  // Timeout request are cancelled
  else if (isCancelled) {
    if (!!fireToast) {
      Toast.info(i18n.t("validation:api.errors.cancelled"));
    }
    return {
      checked: true,
      status: "cancelled",
      message: i18n.t("validation:api.errors.cancelled"),
    };
  }
  // 404
  else if (response?.status === 404) {
    if (!!fireToast) {
      Toast.warning(i18n.t("validation:api.errors.nonexistent"));
    }

    return {
      checked: true,
      status: 404,
      message: i18n.t("validation:api.errors.nonexistent"),
    };
  }
  // 500 and 502
  else if ([500, 502].includes(response?.status)) {
    if (!!fireToast) {
      Toast.error(i18n.t("validation:api.errors.internal"));
    }

    return {
      checked: true,
      status: 500,
      message: i18n.t("validation:api.errors.internal"),
    };
  }
  // 400
  else if (response?.status === 400) {
    let x = Object.keys(response?.data)[0];

    if (typeof response?.data[x] == "object") {
      let y = Object.keys(response?.data[x])[0];
      if (Array.isArray(response?.data[x][y])) {
        if (!!fireToast) {
          Toast.warning(response?.data[x][y][0]);
        }
        return {
          checked: true,
          status: 400,
          message: response?.data[x][y][0],
        };
      }
    }

    let message =
      typeof response?.data[x] === "string"
        ? response?.data[x]
        : response?.data[x][0];

    if (!!fireToast) {
      Toast.warning(message);
    }

    return {
      checked: true,
      status: 400,
      message,
    };
  }
  // 403 and 424
  else if ([403, 424].includes(response?.status)) {
    if (!!fireToast) {
      Toast.warning(
        response?.data?.detail ?? i18n.t("validation:api.errors.empty")
      );
    }
    return {
      checked: true,
      status: response?.status,
      message: response?.data?.detail ?? i18n.t("validation:api.errors.empty"),
    };
  }
  // 429
  else if ([429, 0].includes(response?.status)) {
    if (!!fireToast) {
      Toast.warning(i18n.t("validation:api.errors.too_many_requests"));
    }
    return {
      checked: true,
      status: response?.status,
      message: i18n.t("validation:api.errors.too_many_requests"),
    };
  }
  return {
    checked: false,
    status: null,
    message: null,
  };
}

export function getLocale(lng = i18n.language) {
  if (lng.match("en")) {
    return "en-US";
  } else if (lng.match("es")) {
    return "es-ES";
  } else {
    return "pt-BR";
  }
}

export function getDateFnsLocale(lng = i18n.language) {
  if (lng.match("en")) {
    return en;
  } else if (lng.match("es")) {
    return es;
  } else {
    return pt;
  }
}

export function getTimeToStr(time) {
  if (!time) return "-";

  let month = time.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = time.getDate();
  if (day < 10) day = "0" + day;
  const year = time.getFullYear();

  let hour = time.getHours();
  if (hour < 10) hour = "0" + hour;
  let minutes = time.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;

  return (
    day +
    "/" +
    month +
    " " +
    i18n.t("common:hour.to") +
    " " +
    hour +
    ":" +
    minutes
  );
}

export function getTimeToStrNew(time, lng = i18n.language) {
  if (!time) return "-";

  if (lng.match("en")) {
    const formattedDateEN = format(new Date(time), "MMMM dd',' yyyy", {
      locale: en,
    });
    return formattedDateEN;
  } else if (lng.match("es")) {
    const formattedDateES = format(new Date(time), "dd 'de' MMMM 'de' yyyy", {
      locale: es,
    });
    return formattedDateES;
  } else {
    const formattedDateDefault = format(
      new Date(time),
      "dd 'de' MMMM 'de' yyyy",
      { locale: pt }
    );
    return formattedDateDefault;
  }
}

export const getFormattedDate = (date, format = "DD/MM/YYYY") => {
  return moment(date).format(format);
};

export const countPages = (items, divider) => {
  let pages;
  let rest = items % divider;

  if (rest === 0) pages = items / divider;
  else pages = Math.floor(items / divider) + 1;

  return pages;
};

export const getShowSubItems = (state, action) => {
  let updatedShowSubItems = [...state.showSubItems];
  const isOpen = updatedShowSubItems?.includes(action.payload.name);

  if (!isOpen) {
    updatedShowSubItems?.push(action.payload.name);
  } else {
    updatedShowSubItems = updatedShowSubItems.filter(
      (item) => item !== action.payload.name
    );
  }
  return updatedShowSubItems;
};

export const getEstablishmentCurrentPathLocation = (location) => {
  let currentPath = !location ? "Dashboard" : "";

  if (location === "/dashboard") {
    currentPath = "Dashboard";
  }
  if (location.startsWith("/dashboard/freelancer/minha-base")) {
    currentPath = "My-Base";
  }
  if (location.startsWith("/dashboard/freelancer/jobs")) {
    currentPath = "Jobs";
  }
  if (location.startsWith("/dashboard/freelancer/carteira")) {
    currentPath = "Wallet";
  }
  if (location.startsWith("/dashboard/freelancer/relatorios")) {
    currentPath = "Reports";
  }
  if (location.startsWith("/dashboard/recruta")) {
    currentPath = "Recruit";
  }
  if (location.startsWith("/dashboard/recruta/assinatura")) {
    currentPath = "Subscription";
  }

  if (location.startsWith("/dashboard/treinamentos/videos")) {
    currentPath = "Videos";
  }
  if (location.startsWith("/dashboard/treinamentos/educloseer")) {
    currentPath = "EduCloseer";
  }

  if (location.startsWith("/dashboard/subcontas")) {
    currentPath = "Sub-Accounts";
  }

  if (
    location.startsWith("/dashboard/configuracoes") ||
    location.startsWith("/dashboard/configuracoes/notificacoes") ||
    location.startsWith("/dashboard/configuracoes/custos")
  ) {
    currentPath = "Settings";
  }

  return currentPath;
};

export const getBigcompanyOrSupervisorCurrentPathLocation = (location) => {
  let currentPath = !location ? "Home" : "";

  if (
    location === "/adm" ||
    location === "/spv" ||
    /^([/]spv[/]unidades|[/]adm[/]unidades)/.test(location)
  ) {
    currentPath = "Home";
  }

  if (location.startsWith("/adm/usuarios")) {
    currentPath = "Users";
  }

  if (location.startsWith("/adm/recruta")) {
    currentPath = "Recruit";
  }
  if (location.startsWith("/adm/recruta/assinatura")) {
    currentPath = "Subscription";
  }

  if (
    /^([/]spv[/]freelancer[/]carteira|[/]adm[/]freelancer[/]carteira)/.test(
      location
    )
  ) {
    currentPath = "Wallet";
  }
  if (
    /^([/]spv[/]freelancer[/]jobs|[/]adm[/]freelancer[/]jobs)/.test(location)
  ) {
    currentPath = "Jobs";
  }

  if (location.startsWith("/adm/treinamentos/videos")) {
    currentPath = "Videos";
  }
  if (location.startsWith("/adm/treinamentos/educloseer")) {
    currentPath = "EduCloseer";
  }

  if (
    /^([/]spv[/]configuracoes|[/]adm[/]configuracoes|[/]adm[/]configuracoes[/]notificacoes|[/]adm[/]configuracoes[/]custos)/.test(
      location
    )
  ) {
    currentPath = "Settings";
  }

  if (/^([/]spv[/]aprovacoes|[/]adm[/]aprovacoes)/.test(location)) {
    currentPath = "Approvals";
  }

  return currentPath;
};

export const getFullAddress = (_address) => {
  if (!_address) return "-";
  if (!!_address.length) _address = _address[0];
  return `${_address?.address}, ${_address?.number}, ${_address?.neighb?.name}, ${_address?.neighb?.city?.name} - ${_address?.neighb?.city?.state?.uf}`;
};

export const getReducedAddress = (_address) => {
  if (!_address) return "-";
  if (!!_address.length) _address = _address[0];

  let address = `${_address.address}, ${_address.number}.`;
  return address;
};

export const formatPhone = (value, formatWithoutCountryCode) => {
  let phone = "";
  if (typeof value === "string") {
    if (value.replace(/\D+/g, "").startsWith("55")) {
      phone = value
        .replace(/\D+/g, "")
        .replace(/(\d{2})(\d{2})(\d{5})(\d)/, "+$1 ($2) $3-$4");
    } else if (formatWithoutCountryCode) {
      phone = value
        .replace(/\D+/g, "")
        .replace(/(\d{2})(\d{1})(\d{4})(\d)/, "($1) $2 $3-$4");
    } else {
      phone = value.replace(/\D+/g, "").replace(/(\d{2})(\d)/, "+$1 $2");
    }
  }
  return phone;
};

export const formatDoc = ({ value, type = "cpf" }) => {
  let pattern = "";

  if (type?.toLowerCase() === "cpf") {
    pattern = "999.999.999-99";
  } else if (type?.toLowerCase() === "cnpj") {
    pattern = "99.999.999/9999-99";
  }

  return toPattern(value, pattern);
};

export const getFormatedPixKey = ({ key, type }) => {
  switch (type.toLowerCase()) {
    case "phone":
      return formatPhone(key);
    case "cnpj":
      return formatDoc({ value: key, type: "cnpj" });
    case "cpf":
      return formatDoc({ value: key, type: "cpf" });
    default:
      return value;
  }
};

export const getFormatedPhone = (_phone) => {
  if (!_phone) return "-";

  _phone = _phone.split("");
  let phone = `+${_phone[0]}${_phone[1]} `;
  phone += `(${_phone[2]}${_phone[3]}) `;
  phone += `${_phone[4]}${_phone[5]}${_phone[6]}${_phone[7]}${_phone[8]}-`;
  phone += `${_phone[9]}${_phone[10]}${_phone[11]}${_phone[12]}`;

  return phone;
};

export const getFormatedCEP = (_cep) => {
  if (!_cep) return "-";

  _cep = _cep.split("");
  const cep = `${_cep[0]}${_cep[1]}${_cep[2]}${_cep[3]}${_cep[4]}-${_cep[5]}${_cep[6]}${_cep[7]}`;

  return cep;
};

export const getFormatedCNPJ = (_cnpj) => {
  if (!_cnpj) return "-";

  _cnpj = _cnpj.split("");
  let cnpj = `${_cnpj[0]}${_cnpj[1]}.${_cnpj[2]}${_cnpj[3]}${_cnpj[4]}`;
  cnpj += `.${_cnpj[5]}${_cnpj[6]}${_cnpj[7]}`;
  cnpj += `/${_cnpj[8]}${_cnpj[9]}${_cnpj[10]}${_cnpj[11]}-${_cnpj[12]}${_cnpj[13]}`;

  return cnpj;
};

export const getFormatedDate = (date, separator) => {
  if (!date) return;

  const _date = new Date(date);

  let day = _date.getDate();
  let month = _date.getMonth();
  let year = _date.getFullYear();

  day = `${day < 10 ? `0${day}` : day}`;
  month += 1;
  month = `${month < 10 ? `0${month}` : month}`;
  let divider = separator ? separator : "-";
  return `${day}${divider}${month}${divider}${year}`;
};

export const removeChars = (stringValue) =>
  stringValue.replace("_", "").replace(/[^\w]/g, "");

export const getMediaUrl = (mediaUrl) => {
  if (!mediaUrl) {
    return "";
  }

  if (mediaUrl.indexOf("storage.googleapis") >= 0) {
    return mediaUrl;
  }

  if (mediaUrl.indexOf("secure-doc") !== -1) {
    return `${process.env.REACT_APP_AUTH_URL}${mediaUrl}`;
  } else {
    if (mediaUrl.indexOf("media") >= 0) {
      return `${process.env.REACT_APP_AUTH_URL}${mediaUrl}`;
    }
    return `${process.env.REACT_APP_AUTH_URL}media/${mediaUrl}`;
  }
};

export const getBackgroundUserImage = (picture) => {
  if (!picture) return `url(${DefaultUserPicture})`;

  if (picture.indexOf("storage.googleapis") >= 0) return `url(${picture})`;

  if (!picture.includes("secure")) {
    if (!picture.includes("media")) {
      picture = `media/${picture}`;
    }
  }

  return `url(${process.env.REACT_APP_AUTH_URL}${picture}`;
};

export const getMonthReduce = (month) => {
  return MONTHS_REDUCE[month - 1];
};

export const getMonthReduceSigla = (month) => {
  return MONTHS_REDUCE_SIGLA[month - 1];
};

export function getObjectName(x) {
  let name = i18n.t("dashboard:card_extract.status");

  if (x.includes("extra money"))
    name = i18n.t("dashboard:card_extract.extra_money");
  else if (x.includes("job offer"))
    name = i18n.t("dashboard:card_extract.job_done");
  else if (x.includes("fee to"))
    name = i18n.t("dashboard:card_extract.deposit_fee");
  else if (x.includes("credit request"))
    name = i18n.t("dashboard:card_extract.status_6");
  else if (x.includes("dashboard | log user actions"))
    name = i18n.t("dashboard:card_extract.transfer_units");

  return name;
}

export const identifyStatusItemExtract = (item, theme) => {
  let objectItemExtract = {
    color: theme.colors.green_5,
    background: theme.colors.green_1,
    icon: money20Regular,
  };

  if (item.value < 0) {
    objectItemExtract = {
      icon: personAvailable20Regular,
      color: theme.colors.danger_6,
      background: theme.colors.danger_1,
    };
  }

  if (item?.content_str?.includes("fee to")) {
    objectItemExtract = {
      image: (
        <img
          src={depositFeeIcon}
          alt="Closeer Tax"
          style={{ aspectRadio: 1, width: "100%", maxWidth: "1.5rem" }}
        />
      ),
      color: theme.colors.danger_6,
      background: theme.colors.danger_1,
    };
  }

  return objectItemExtract;
};

export const getExtract = async () => {
  try {
    const { data } = await mainService.get("/wallet/transactions/");
    return data;
  } catch (error) {}
};

export function formatDateToRequest({
  _d = new Date(),
  separator = "-",
  time = false,
  brazilianFormat = false,
}) {
  let date = _d;

  let day = String(date.getDate()).padStart(2, "0");
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0");

  let hours = String(date.getHours()).padStart(2, "0");
  let minutes = String(date.getMinutes()).padStart(2, "0");

  let date_string = null;
  if (!!brazilianFormat) {
    date_string = [day, month, year].join(separator);
  } else {
    date_string = [year, month, day].join(separator);
  }

  let time_string = time ? [hours, minutes].join(":") : "";

  let final_string = [date_string, time_string].join(" ").trim();

  return final_string;
}

export function getPeriodShort({ start = new Date(), end = new Date(), lng }) {
  let start_date = moment(start);
  let end_date = moment(end);
  let format = "";

  if (lng.match("en")) {
    format = "MM/DD/YY";
  } else {
    format = "DD/MM/YY";
  }

  return `${start_date.format(format)} - ${end_date.format(format)}`;
}

export const getFormatedCurrency = (value = 0, forcePositive = false) => {
  let result = value;
  if (typeof value === "string") {
    let parse = result.replace(/[,]/g, ".");
    result = Number(parse);
  }

  if (forcePositive) {
    result = Math.abs(result);
  }

  return Number(result).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const millisecondsToHour = 3600000;

export const openExternalLink = (url) => {
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");

  setTimeout(() => {
    link.click();
  }, 500);
};

export const sendEmail = ({ email = "", subject = "", body = "" }) => {
  const url = `mailto:${email}?subject=${subject}&body=${body}`;
  openExternalLink(url);
};

export function getAge(birthDate = "") {
  const age = moment().diff(birthDate, "years");
  return age;
}

export function copyToClipboard({
  txt = "",
  onSuccess = () => {},
  onError = () => {},
}) {
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state == "granted" || result.state == "prompt") {
      navigator.clipboard.writeText(txt).then(
        function () {
          onSuccess?.();
        },
        function () {
          onError?.();
        }
      );
    }
  });
}

export const extractParamValue = ({ key = "", search = {} }) => {
  let array = search
    .replace("?", "")
    .split("&")
    .map((item) => ({
      key: item.split("=")[0],
      value: item.split("=")[1] ?? "",
    }));

  return array?.find((item) => item.key === key)?.value ?? "";
};

export const getFormatedRole = (str) => {
  return str?.split("/").join("/\n") ?? "";
};

export const getFormatedAddress = (address) => {
  if (typeof address !== "object") {
    return "";
  }

  let _str_ = "";

  if (address) {
    _str_ = [
      address?.address,
      address?.number,
      address?.neighb?.name,
      address?.neighb?.city?.name,
      address?.neighb?.city?.state?.uf,
      address?.country_name,
      address?.zipcode,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return _str_;
};

export function handleLogout(callback = () => {}) {
  Cookie.remove("token-administrator");
  Cookie.remove("token-supervisor");
  Cookie.remove("token-manager");
  Cookie.remove("unit-pk");
  localStorage.removeItem("wasOpenedModalFreemium");

  try {
    OneSignal.User.removeTag("uuid");
  } catch (error) {}

  store.dispatch(StatusActions.reset());
  store.dispatch(UserActions.reset());
  store.dispatch(PersistFilters.clear());
  callback();
}

export function getDateAndHour(date) {
  let _date = moment(date);
  return { date: _date.format("DD/MM/YYYY"), hour: _date.format("HH:mm") };
}

export function getTimeHours(time = "") {
  let _time = moment(time).format("HH:mm");
  return _time;
}

export function getTransactionsWithDetails(item) {
  const transactions = [
    "commercial | credit request",
    "commercial | big company credit request",
  ];

  return transactions.includes(item?.content_str);
}

export function convertMoney(money = "") {
  return String(money).trim().replace(/[.]/g, "").replace(",", ".");
}

export function extractRootPath(pathname = "") {
  let path = pathname || window.location.pathname;
  return path?.split("/")?.slice(0, 2)?.join("/");
}

export function getBrowserVersion() {
  const { userAgent } = navigator;
  let log;
  if (userAgent.includes("Firefox/")) {
    log = `Firefox v${userAgent.split("Firefox/")[1]}`;
  } else if (userAgent.includes("Edg/")) {
    log = `Edge v${userAgent.split("Edg/")[1]}`;
  } else if (userAgent.includes("Chrome/")) {
    log = `Chrome v${userAgent.split("Chrome/")[1]}`;
  } else if (userAgent.includes("Safari/")) {
    log = `Safari v${userAgent.split("Safari/")[1]}`;
  }
  return log;
}

export function getInfoUsers(state) {
  let user;
  if (
    state?.user?.data?.account_type === "adm" &&
    !!state?.status?.data?.user
  ) {
    user =
      "Bigcompany: " +
      state?.user?.data?.email +
      "\n" +
      "Estabelecimento: " +
      state?.status?.data?.user?.email;
  } else if (state?.user?.data?.account_type === "adm") {
    user = "Bigcompany: " + state?.user?.data?.email;
  } else if (
    state?.user?.data?.account_type === "supervisor" &&
    !!state?.status?.data?.user
  ) {
    user =
      "Supervisor: " +
      state?.user?.data?.email +
      "\n" +
      "Estabelecimento: " +
      state?.status?.data?.user?.email;
  } else if (state?.user?.data?.account_type === "supervisor") {
    user = "Supervisor: " + state?.user?.data?.email;
  } else if (!!state?.status?.data?.user) {
    user = "Estabelecimento: " + state?.status?.data?.user?.email;
  }

  return user;
}

export async function getAddress({ form, place }) {
  const results = await geocodeByPlaceId(place?.value?.place_id);
  const location = await getLatLng(results[0]);

  let newPlace = {
    ...form,
  };

  results[0]?.address_components?.forEach((item) => {
    if (item.types.indexOf("street_number") > -1) {
      newPlace = { ...newPlace, number: item.long_name };
    } else if (item.types.indexOf("postal_code") > -1) {
      newPlace = { ...newPlace, zipcode: item.long_name };
    } else if (item.types.indexOf("route") > -1) {
      newPlace = { ...newPlace, street: item.long_name };
    } else if (item.types.indexOf("sublocality_level_1") > -1) {
      newPlace = { ...newPlace, neighbourhood: item.long_name };
    } else if (item.types.indexOf("administrative_area_level_2") > -1) {
      newPlace = { ...newPlace, city: item.long_name };
    } else if (item.types.indexOf("country") > -1) {
      newPlace = { ...newPlace, country: item.long_name };
    }
  });

  results[0]?.address_components?.forEach((item) => {
    if (item.types.indexOf("administrative_area_level_1") > -1) {
      if (newPlace.country === "Brasil") {
        newPlace = { ...newPlace, state: item.short_name };
      } else if (newPlace.country === "Argentina") {
        newPlace = { ...newPlace, state: item.long_name };
      }
    }
  });

  newPlace = {
    ...newPlace,
    location,
    place_id: results[0]?.place_id,
    full_address: results[0]?.formatted_address,
  };

  return newPlace;
}

export const getURLParams = (params = "") => {
  return String(decodeURIComponent(params))
    .replace("?", "")
    .split("&")
    .reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});
};

export const recalculatePage = ({ count, page_size, current_page }) => {
  const total_pages = Math.ceil(count / page_size);

  if (current_page > total_pages) {
    current_page = total_pages;

    if (!total_pages) {
      current_page = 1;
    }
  }

  return current_page;
};

export const formatMultipleDates = (dates) => {
  return dates
    ?.map((day) => {
      if (!!day?.new_start_date) {
        return moment(day?.new_start_date)?.format("DD/MM");
      }
      return moment(day?.start_date)?.format("DD/MM");
    })
    .join(" - ");
};

export function removeFormatCpf(str = "") {
  return String(str)
    ?.trim()
    ?.replace(/[^\d]+/g, "");
}

export function isCPF(str = "") {
  let isValid = true;
  const CPF_CHAR_LENGTH = 11;
  const cpf = removeFormatCpf(str);
  const know_invalid_cpfs = Array.from({ length: 10 }, (_, idx) =>
    String(idx).repeat(CPF_CHAR_LENGTH)
  );
  // Elimina CPFs invalidos conhecidos
  if (know_invalid_cpfs.includes(cpf)) {
    return false;
  }
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) isValid = false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) isValid = false;
  return isValid;
}

export function isCnpj(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let after = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * after--;
    if (after < 2) after = 9;
  }
  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado != digits.charAt(0)) return false;

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  after = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * after--;
    if (after < 2) after = 9;
  }
  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado != digits.charAt(1)) return false;

  return true;
}

export const TRAINING_ALIFE_PRO = [59]; // TODO MVP training/educloseer Alife

export const filterAndFormatRolesByGlobalRolesCustomConfig = ({
  onlyCustomRoles,
  array,
}) => {
  let formatedRoles = array
    ?.filter((item) => (onlyCustomRoles ? item?.role?.is_custom : true))
    .map((item) => ({
      role: {
        name: item?.role?.name,
        pk: item?.role?.id || item?.role?.pk,
      },
    }));

  return formatedRoles;
};

export const filterAndFormatRolesByGlobalRolesCustomNames = ({
  onlyCustomRoles,
  array,
}) => {
  let formatedRoles = array
    ?.filter(
      (item) =>
        item?.role?.name && (onlyCustomRoles ? item?.role?.is_custom : true)
    )
    .map((item) => getCapitalizeName(item?.role?.name));

  return formatedRoles;
};

export const removeDiacritics = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const removeNonNumeric = (phone) => {
  return phone?.replace(/\D/g, "");
};

export const formatPhoneNumber = (phoneNumber) => {
  return toPattern(phoneNumber, "+99 (99) 99999-9999");
};

export const getCalcPage = ({ count, pageSize, page }) => {
  const calcPage = Math.ceil(count / pageSize);
  if (page > calcPage) {
    page = calcPage;
    if (calcPage <= 0) {
      page = 1;
    }
  }
  return page;
};

export const workerWhatsAppLink = ({ phone }) => {
  return `https://api.whatsapp.com/send/?phone=${phone}&text&app_absent=0`;
};

export const WORKER_STATUS = {
  DOCUMENT_HASNT_SENT: "DOCUMENT_HASNT_SENT",
  DOCUMENT_MISSING: "DOCUMENT_MISSING",
  DOCUMENT_PROBLEM: "DOCUMENT_PROBLEM",
  PENDING: "PENDING",
  PENDING_MANUAL_VALIDATION: "PENDING_MANUAL_VALIDATION",
  DOCUMENT_OK: "DOCUMENT_OK",
  OK: "OK",
  DENIED: "DENIED",
  REMOVED: "REMOVED",
};

/**
 * @param {String} date
 * @returns {String}
 * @example
 * formatDateToMonthYear("2022-01-01") // "Jan/2022"
 *
 */
export const formatDateToMonthYear = (date) => {
  const _date = new Date(date);
  return `${MONTHS_REDUCE[_date.getUTCMonth()]}/${_date.getUTCFullYear()}`;
};

/**
 * @param {String} value
 * @returns {String}
 * @example
 * getFormatMoney(1000) // R$ 1.000,00
 */
export const getFormatMoney = (value = 0) => {
  return `${i18n.t("common:$")} ${getFormatedCurrency(value)}`;
};

/**
 * @param {String} date
 * @returns {String}
 * @example
 * formatDateToYYYYMMDD("Mon Dec 30 2024 13:06:33 GMT-0300 (Horário Padrão de Brasília)") // "2024-12-30"
 */
export const formatDateToYYYYMMDD = (date) => {
  const _date = new Date(date);
  const year = _date.getUTCFullYear();
  const month = String(_date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(_date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * @param {String} root
 * @returns {Array}
 * @example
 * getRoutesPrevious({root: "dashboard"})
 */
export const getRoutesPrevious = ({ root = "adm" } = {}) => {
  const LINKS = {
    configuracoes: {
      route: "/configuracoes",
      translate: "security:breadcrumber.settings",
    },
    seguranca: {
      route: "/configuracoes/seguranca",
      translate: "security:breadcrumber.security",
    },
  };

  const route = window.location.pathname.replace(/^\//, "").replace(root, "");

  let routes = route.split("/").filter((segmento) => segmento !== "");
  routes.pop();

  return routes.map((routeItem) => ({
    path: extractRootPath(window.location.pathname) + LINKS[routeItem]?.route,
    name: i18n.t(LINKS[routeItem]?.translate),
  }));
};
