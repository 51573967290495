const INITIAL_STATE = {
  hasOpened: false,
};

/**
 * TYPES
 */
export const Types = {
  SET_MODAL_STATUS: "set-automaticModal-status",
};

export function automaticModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_MODAL_STATUS:
      return {
        ...state,
        hasOpened: action.payload.hasOpened,
      };
    default:
      return state;
  }
}

export const Creators = {
  setModalStatus: (hasOpened) => ({
    type: Types.SET_MODAL_STATUS,
    payload: { hasOpened },
  }),
};
