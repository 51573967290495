import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import resources from "translations";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "pt",
    defaultNS: "common",
    detection: {
      order: [
        "sessionStorage",
        "cookie",
        "localStorage",
        "querystring",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
      cookieMinutes: 3,
      lookupCookie: "@lng_app",
      lookupSessionStorage: "@lng_app",
    },
    interpolation: {
      escapeValue: false,
      format: function (value, format = null, lng) {
        if (value instanceof Date) return value.toISOString();
        if (value.toLowerCase().startsWith("money_"))
          return new Intl.NumberFormat().format(Number(value.split("_")[1]));
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

window.i18n = i18n;

export default i18n;
