import mainService from "services/mainService";

/**
 * --------------------------------------------------
 * @param {number} pkBigcompany
 * @param {string} inputUnits
 * @example
 * listUnits({ inputUnits: "unidade", pkBigcompany: "2" })
 */
export function listUnits({ pkBigcompany, inputUnits = "" }) {
  return mainService.get(
    `v2/bigcompany/${pkBigcompany}/units/names_2?establishment_address=true&establishment_active=true&name=${inputUnits}`
  );
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * duplicateCancelledJob({ offerId: 124 })
 */
export function duplicateCancelledJob({ offerId }) {
  return mainService.post(`v2/jobs/offer/${offerId}/duplicate_cancelled_job`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * getOfferDetails({ offerId: 124 })
 */
export function getOfferDetails({ offerId }) {
  return mainService.get(`v2/jobs/offer/${offerId}/establishment_v2`);
}

/**
 * --------------------------------------------------
 * @param {number} jobId
 * @example
 * getJobDetails({ jobId: 124 })
 */
export function getJobDetails({ jobId }) {
  return mainService.get(`v2/jobs/establishment/job/${jobId}/detail`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * getWorkerByOffer({ offerId: 124 })
 */
export function getWorkerByOffer({ offerId }) {
  return mainService.get(`v2/jobs/offer/${offerId}/establishment/worker`);
}

/**
 * --------------------------------------------------
 * @param {number} jobId
 * @example
 * getApprovalDetails({ jobId: 124 })
 */
export function getApprovalDetails({ jobId }) {
  return mainService.get(`v2/jobs/approvals/jobs/${jobId}/detail`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * getOfferExtraValues({ offerId: 124 })
 */
export function getOfferExtraValues({ offerId }) {
  return mainService.get(`v2/jobs/offer/${offerId}/extra_money/establishment`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * revertJob({ offerId: 124 })
 */
export function revertJob({ offerId }) {
  return mainService.post(`v2/jobs/offer/${offerId}/revert_job_started`);
}

/**
 * --------------------------------------------------
 * @param {number} offerId
 * @example
 * rateJob({ offerId: 124 })
 */
export const rateJob = ({ body, offerId }) => {
  return mainService.post(`v2/jobs/offer/${offerId}/rate`, body);
};
